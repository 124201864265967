<template>
  <div class="home pt-main">
    <div>
      <section class="mx-5">
        <div class="home-header-bg container-fluid">
          <div class="row home-header-icons">
            <div
              class="col-md-6 d-flex flex-column align-items-start justify-content-center head-icons"
            >
              <div
                class="d-flex flex-column align-items-center justify-content-around h-100"
              >
                <img
                  src="../assets/images/Line 3.svg"
                  class="img-fluid hero-icons"
                  alt=""
                />
                <a
                  href="https://www.facebook.com/rebecca.t.cameron.3"
                  target="_blank"
                  ><img
                    src="../assets/images/facebook.svg"
                    class="img-fluid hero-icons"
                    alt=""
                /></a>
                <a
                  href="https://www.instagram.com/rebecca.t.cameron.3/"
                  target="_blank"
                  ><img
                    src="../assets/images/instagram.svg"
                    class="img-fluid hero-icons"
                    alt=""
                /></a>
                <a href="https://twitter.com/BecksCameronZA" target="_blank"
                  ><img
                    src="../assets/images/twitter.svg"
                    class="img-fluid hero-icons"
                    alt=""
                /></a>
                <a
                  href="https://www.linkedin.com/in/rebecca-tara-cameron-b7278114b/"
                  target="_blank"
                  ><img
                    src="../assets/images/linkedin.svg"
                    class="img-fluid hero-icons"
                    alt=""
                /></a>
                <a href="https://wa.me/27765753518" target="_blank"
                  ><img
                    src="../assets/images/whatsapp.svg"
                    class="img-fluid hero-icons"
                    alt=""
                /></a>
                <img
                  src="../assets/images/Line 3.svg"
                  class="img-fluid hero-icons"
                  alt=""
                />
              </div>
            </div>
            <div class="col-md-6 pe-md-5 d-flex flex-column">
              <div
                class="row p-5 h-100 d-none d-lg-flex flex-column justify-content-center align-items-center"
              >
                <div class="hero-banner-text ps-md-5">
                  <div class="sticky">
                    <img
                      src="../assets/images/agile-sticks-text2.png"
                      alt=""
                      class="img-fluid"
                    />
                  </div>
                  <div class="">
                    <h3 class="text-dark-grey text-indie pb-5 ps-5">
                      Once you go agile, you will never be fragile
                    </h3>
                  </div>

                  <div class="d-flex flex-column flex-md-row">
                    <!-- <div class="col-md-6 col-12">
                      <button class="btn btn-orange header-buttons px-5 m-4">
                        My podcast
                      </button>
                    </div> -->
                    <div class="col-12">
                      <a
                        href="https://calendly.com/rebeccacameron/30min"
                        target="_blank"
                        class="btn btn-grey px-5 m-4 text-decoration-underline"
                      >
                        Let's meetup
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <h3 class="text-dark-grey text-indie text-center d-block d-md-none">
              Once you go agile, you will never be fragile
            </h3> -->
              <!-- <div class="row text-center">
              <div class="col-md-6 col-12">
                <button class="btn btn-orange header-buttons px-5 my-4">
                  My podcast
                </button>
              </div>
              <div class="col-md-6 col-12">
                <button class="btn btn-grey header-buttons px-5 my-4">
                  Let's meetup
                </button>
              </div>
            </div> -->
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="row p-4 h-100 d-block d-lg-none">
          <div
            class="hero-banner-text d-flex flex-column justify-content-center align-items-center ps-md-5"
          >
            <div class="sticky">
              <img
                src="../assets/images/agile-sticks-text2.png"
                alt=""
                class="img-fluid"
              />
            </div>

            <h3 class="text-dark-grey text-indie text-center pb-5">
              Once you go agile, you will never be fragile
            </h3>
            <div class="d-flex flex-column flex-md-row">
              <!-- <div class="col-md-6 col-12">
                <button class="btn btn-orange header-buttons px-5 m-4">
                  My podcast
                </button>
              </div> -->
              <div class="col-12">
                <a href="#contact" class="btn btn-grey px-5 m-4">
                  Let's meetup
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="container home-section" id="services">
        <h3 class="text-orange text-indie text-center pb-sml">
          How to navigate this website: When you see a word
          <strong><u>underlined</u></strong
          >, it is hyperlinked to another destination, whether it be a favourite
          article, Tedtalk or some other golden nugget. My intent is to share
          what inspires me.
        </h3>
        <div class="d-md-block d-none">
          <div class="row">
            <div class="col-md-4 b-right b-bottom pb-sml pt-4">
              <h4 class="display-2 text-dark-grey text-indie text-center">
                To Do
              </h4>
            </div>
            <div class="col-md-4 b-right b-bottom pb-sml pt-4">
              <h4 class="display-2 text-dark-grey text-indie text-center">
                Doing
              </h4>
            </div>
            <div class="col-md-4 b-bottom pb-sml pt-4">
              <h4 class="display-2 text-dark-grey text-indie text-center">
                Done
              </h4>
            </div>
          </div>
          <div class="row sticky-notes">
            <div class="col-md-4 b-right pb-sml pt-5">
              <!-- <img
                src="../assets/images/agile-sticks-todo.webp"
                class="img-fluid"
                alt=""
              /> -->
              <ul>
                <li>
                  <router-link to="/services#implementation" class="five mb-3">
                    <img
                      src="../assets/images/Sketching.svg"
                      class="img-fluid mx-auto d-block mb-3"
                      style="width: 65px"
                      alt=""
                    />
                    <h4 class="text-indie text-dark-grey">
                      Agile<br />Implementation
                    </h4>
                  </router-link>
                </li>
                <li>
                  <a
                    href="/services#boot"
                    class="three mt-3 d-flex flex-column"
                  >
                    <h4 class="text-indie text-dark-grey">
                      Leadership<br />Development<br />Bootcamp
                    </h4>
                    <div class="w-100">
                      <img
                        src="../assets/images/Agile Girl.svg"
                        class="img-fluid d-block ms-auto"
                        style="width: 40px; margin-top: -15px"
                        alt=""
                      />
                    </div>
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-md-4 b-right pb-sml pt-4">
              <!-- <img
                src="../assets/images/agile-sticks-doing.webp"
                class="img-fluid"
                alt=""
              /> -->
              <ul>
                <li>
                  <router-link to="/services#meetings" class="four mt-5">
                    <h4 class="text-indie text-dark-grey">
                      Facilitation/<br />
                      Chairing of Meetings
                    </h4>
                    <div class="w-100">
                      <img
                        src="../assets/images/megaphone-hand-drawn-tool-outline.svg"
                        class="img-fluid mx-auto d-block"
                        style="width: 55px"
                        alt=""
                      />
                    </div>
                  </router-link>
                </li>
                <li>
                  <a href="/services#retrospectives" class="one1">
                    <div class="w-100">
                      <img
                        src="../assets/images/market-research.svg"
                        class="img-fluid mx-auto d-block mb-4"
                        style="width: 65px"
                        alt=""
                      />
                    </div>
                    <h4 class="text-indie text-dark-grey">Retrospectives</h4>
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-md-4 px-5 pb-sml pt-5">
              <!-- <img
                src="../assets/images/agile-sticks-done.webp"
                class="img-fluid"
                alt=""
              /> -->
              <ul>
                <li>
                  <router-link to="/services#coaching" class="one mt-3">
                    <div class="w-100">
                      <img
                        src="../assets/images/_x37_8.svg"
                        class="img-fluid mx-auto d-block mb-4"
                        style="width: 60px"
                        alt=""
                      />
                    </div>
                    <h4 class="text-indie text-dark-grey">
                      Coaching/<br />Mentorship
                    </h4>
                  </router-link>
                </li>
                <li>
                  <router-link to="/services#business" class="two mt-3">
                    <h4 class="text-indie text-dark-grey">
                      Business <br />Innovation
                    </h4>
                    <div class="w-100">
                      <img
                        src="../assets/images/sprint.svg"
                        class="img-fluid ms-auto d-block"
                        style="width: 55px"
                        alt=""
                      />
                    </div>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="d-md-none d-block">
          <div class="row sticky-notes">
            <div class="col-md-4 pb-sml pt-5">
              <h4
                class="display-2 text-dark-grey text-indie text-center b-bottom"
              >
                To Do
              </h4>
              <ul>
                <li>
                  <a href="#" class="five mb-3">
                    <h4 class="text-indie text-dark-grey">
                      Leadership<br />Development<br />Bootcamp
                    </h4>
                    <div class="w-100">
                      <img
                        src="../assets/images/Agile Girl.svg"
                        class="img-fluid float-right"
                        style="width: 40px; margin-top: -15px"
                        alt=""
                      />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="#" class="three mt-3 d-flex flex-column">
                    <img
                      src="../assets/images/Sketching.svg"
                      class="img-fluid mx-auto d-block mb-3"
                      style="width: 65px"
                      alt=""
                    />
                    <h4 class="text-indie text-dark-grey">
                      Agile<br />Implementation
                    </h4>
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-md-4 pb-sml pt-5">
              <h4
                class="display-2 text-dark-grey text-indie text-center b-bottom"
              >
                Doing
              </h4>
              <ul>
                <li>
                  <a href="#" class="four mt-5">
                    <div class="w-100">
                      <img
                        src="../assets/images/sprint.svg"
                        class="img-fluid float-right"
                        style="width: 55px"
                        alt=""
                      />
                    </div>

                    <h4 class="text-indie text-dark-grey">
                      Scrum and<br />Kanban training
                    </h4>
                  </a>
                </li>
                <li>
                  <a href="#" class="one1">
                    <div class="w-100">
                      <img
                        src="../assets/images/market-research.svg"
                        class="img-fluid mx-auto d-block mb-4"
                        style="width: 65px"
                        alt=""
                      />
                    </div>
                    <h4 class="text-indie text-dark-grey">Retrospectives</h4>
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-md-4 pb-sml pt-4">
              <h4
                class="display-2 text-dark-grey text-indie text-center b-bottom"
              >
                Done
              </h4>
              <ul>
                <li>
                  <a href="#" class="one mt-3">
                    <div class="w-100">
                      <img
                        src="../assets/images/_x37_8.svg"
                        class="img-fluid mx-auto d-block mb-4"
                        style="width: 60px"
                        alt=""
                      />
                    </div>
                    <h4 class="text-indie text-dark-grey">
                      Coaching/<br />Mentorship
                    </h4>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section class="container">
        <div class="row pt-sml">
          <div class="col-lg-6 text-light-grey pt-5 pb-5">
            <h2 class="text-orange text-indie display-2">Rebecca</h2>
            <h2 class="text-orange text-indie display-2 ps-5 ms-5">Cameron</h2>
            <h3 class="text-prox-l h1 text-dark-grey">
              Agilista | Mind-set Shifta
            </h3>
            <div class="pt-sml">
              <p>
                Who am I? The age old question. I am a little bit of a lot of
                things - agilist, entrepreneur, once-a-lawyer, philosophiser,
                Millennial, and adventurer. I leverage this and love to
                cross-pollinate the wonderful things I get to learn from
                different domains.
              </p>
              <p>
                Using the heart of the Agile Agile Manifesto , I help
                individuals and organisations adopt a new way of thinking and
                being. I do this by creating psychological-safe spaces for
                others to better unpack and understand their processes and
                potential areas for improvement, through open and crucial
                conversations
              </p>
              <div class="pt-sml">
                <router-link
                  to="/about"
                  class="btn btn-orange px-5 mb-5 text-decoration-underline"
                >
                  Learn more about Rebecca
                </router-link>
              </div>
            </div>
          </div>
          <div class="col-lg-6 d-flex align-items-end">
            <img
              src="../assets/images/agile-sticks-table-image2.webp"
              class="img-fluid"
              alt=""
            />
          </div>
        </div>
      </section>
      <section class="bg-dark-grey">
        <div class="container text-white">
          <h3 class="text-indie display-3 pt-sml pb-5">Solutions for you!</h3>
          <p>
            In short, let’s f**ck the politics, call a spade a spade, and get to
            the juicy stuff. This leads to the way to the catalysation and
            co-creation of incredible solutions. The increased ownership of
            these solutions then leads to real action.
          </p>
          <div class="row pb-sml">
            <div class="card-group home-card-group pt-sml">
              <div class="card bg-dark-grey border-0 text-center">
                <div class="">
                  <img
                    src="../assets/images/puzzle.svg"
                    class="card-img-top img-fluid"
                    alt="Puzzle"
                  />
                </div>

                <div class="card-body">
                  <h4 class="card-title pt-sml">
                    Individuals and Interactions
                  </h4>
                </div>
                <div class="card-footer border-0 bg-dark-grey">
                  <p class="card-text">
                    Individuals and interactions over processes and tools.
                  </p>
                </div>
              </div>
              <div class="card bg-dark-grey border-0 text-center">
                <div class="">
                  <img
                    src="../assets/images/giving-present.svg"
                    class="card-img-top img-fluid"
                    alt="giving a present"
                  />
                </div>

                <div class="card-body">
                  <h4 class="card-title pt-sml">Working Products</h4>
                </div>
                <div class="card-footer border-0 bg-dark-grey">
                  <p class="card-text">
                    Working software over comprehensive documentation
                  </p>
                </div>
              </div>
              <div class="card bg-dark-grey border-0 text-center">
                <div class="">
                  <img
                    src="../assets/images/shaking-hands.svg"
                    class="card-img-top img-fluid"
                    alt="Shaking hands"
                  />
                </div>

                <div class="card-body">
                  <h4 class="card-title pt-sml">Collaboration</h4>
                </div>
                <div class="card-footer border-0 bg-dark-grey">
                  <p class="card-text">
                    Customer collaboration over contract negotiation
                  </p>
                </div>
              </div>
              <div class="card bg-dark-grey border-0 text-center">
                <div class="">
                  <img
                    src="../assets/images/arrows.svg"
                    class="card-img-top img-fluid"
                    alt="Arrows"
                  />
                </div>

                <div class="card-body">
                  <h4 class="card-title pt-sml">Respond to change</h4>
                </div>
                <div class="card-footer border-0 bg-dark-grey">
                  <p class="card-text">
                    Responding to change over following a plan
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "HomeView",
};
</script>

<template>
  <div class="header">
    <nav class="navbar navbar-expand-lg navbar-light bg-white fixed-top">
      <div class="container">
        <router-link class="navbar-brand" to="/"
          ><img
            src="../assets/images/AgileSticks-logo.png"
            class="img-fluid"
            alt=""
        /></router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul
            class="navbar-nav mb-2 mb-lg-0 text-uppercase d-flex justify-content-md-end w-100 justify-content-center align-items-center"
          >
            <li class="nav-item">
              <router-link class="nav-link text-center" to="/about"
                >About</router-link
              >
            </li>
            <li class="nav-item">
              <router-link class="nav-link text-center" to="/services"
                >Services</router-link
              >
            </li>
            <!--  <li class="nav-item">
              <router-link class="nav-link" to="/services">Follow</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/services">FAQ</router-link>
            </li> -->
            <li class="nav-item">
              <a class="nav-link text-center" href="#contact">Contact</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "HeaderMain",
};
</script>

<template>
  <div class="hello">
    <section class="bg-orange">
      <div class="container pt-sml pb-sml">
        <div class="row">
          <div
            class="col-md-12 d-flex align-items-center justify-content-center"
          >
            <h1 class="text-white text-indie mt-2 d-none d-md-block">
              What my clients say...
            </h1>
            <img
              src="../assets/images/heart-message.svg"
              alt=""
              class="img-fluid ps-5"
            />
          </div>
          <h1 class="text-white text-indie mt-2 d-block d-md-none">
            What my clients say...
          </h1>

          <carousel
            class="d-flex justify-content-center align-items-center h-100"
            :per-page="1"
            :paginationEnabled="false"
            :mouse-drag="true"
            :autoplay="true"
            :loop="true"
            :autoplayHoverPause="true"
            :autoplayTimeout="5000"
            @page-change="pageChange"
          >
            <slide
              class="d-flex align-items-center justify-content-around flex-md-row flex-column"
            >
              <div class="col-md-2 offset-md-2">
                <div class="pt-sml d-flex justify-content-center">
                  <img
                    src="../assets/images/Wayne-Kruger.webp"
                    class="img-fluid reviews"
                    alt=""
                  />
                </div>
              </div>
              <div class="col-md-6 pt-md-5 d-flex justify-container-center">
                <div class="pt-sml">
                  <p class="text-white fst-italic px-3">
                    I recently had the pleasure of observing Rebecca work with a
                    client. She had just the right balance of structure and
                    flexibility that made it easy for her to cope with telecoms
                    challenges in the session, without it interfering in the
                    delivery of her material. She was warm and personable and
                    clearly informed, so a balance between competence and
                    empathy! I won’t hesitate to use her service in the future
                    and highly recommend her as a facilitator.
                  </p>
                  <h4 class="fw-bold text-white px-3">
                    - Wayne Kruger | Synchronicity.
                  </h4>
                </div>
              </div>
              <h5 class="pt-5 text-light-blue"></h5>
              <!-- <img
                src="../../images/Shritama-Saha.png"
                class="img-fluid mb-4 img-thumb-size2 btn-why-intern slider-image-intern"
                alt=""
              /> -->
            </slide>
            <slide
              class="d-flex align-items-center justify-content-around flex-md-row flex-column"
            >
              <div class="col-md-2 offset-md-2">
                <div class="pt-sml d-flex justify-content-center">
                  <img
                    src="../assets/images/Gail-Baerecke.webp"
                    class="img-fluid reviews"
                    alt=""
                  />
                </div>
              </div>
              <div class="col-md-6 pt-md-5 d-flex justify-container-center">
                <div class="pt-sml">
                  <p class="text-white fst-italic px-3">
                    When I met and got to work with Rebecca from AGILE, she
                    introduced me to a whole new meaning for the Post-It. We
                    found her at a time when change was taking place in our
                    business and planning and a clear head was needed to plot
                    and plan the future. Her practical way of looking at things
                    helped us to categorise what was needed to be done, allocate
                    who needed to do what and mobilise the team to implement it.
                    She was literally a breath of fresh air.
                  </p>
                  <h4 class="fw-bold text-white px-3">
                    - Gail Baerecke | Chris Burger Petro Jackson Players' Fund.
                  </h4>
                </div>
              </div>
              <h5 class="pt-5 text-light-blue"></h5>
              <!-- <img
                src="../../images/Shritama-Saha.png"
                class="img-fluid mb-4 img-thumb-size2 btn-why-intern slider-image-intern"
                alt=""
              /> -->
            </slide>
            <slide
              class="d-flex align-items-center justify-content-around flex-md-row flex-column"
            >
              <div class="col-md-2 offset-md-2">
                <div class="pt-sml d-flex justify-content-center">
                  <img
                    src="../assets/images/Kimberley-Taylor.webp"
                    class="img-fluid reviews"
                    alt=""
                  />
                </div>
              </div>
              <div class="col-md-6 pt-md-5 d-flex justify-container-center">
                <div class="pt-sml">
                  <p class="text-white fst-italic px-3">
                    Rebecca has been working with our company since September
                    2019. It has been an absolute pleasure, and I have
                    personally witnessed how the team has grown, including
                    myself. Our team is a remote team, but Rebecca was able to
                    help us overcome the challenges of not always being in the
                    same vicinity. Communication channels were smoother, and she
                    helped create opportunities for connection. The
                    retrospectives held have been very valuable in improving
                    honest and open conversations between all of us; revealing
                    issues before they become bigger and unsolvable; and we have
                    been able to collectively adapt our processes as a team. I
                    have recommended her to numerous people and will continue to
                    do so.
                  </p>
                  <h4 class="fw-bold text-white px-3">
                    - Kimberley Taylor | Founder and CEO of Loop
                  </h4>
                </div>
              </div>
              <h5 class="pt-5 text-light-blue"></h5>
              <!-- <img
                src="../../images/Shritama-Saha.png"
                class="img-fluid mb-4 img-thumb-size2 btn-why-intern slider-image-intern"
                alt=""
              /> -->
            </slide>
            <slide
              class="d-flex align-items-center justify-content-around flex-md-row flex-column"
            >
              <div class="col-md-2 offset-md-2">
                <div class="pt-sml d-flex justify-content-center">
                  <img
                    src="../assets/images/Wayne-Kruger.webp"
                    class="img-fluid reviews"
                    alt=""
                  />
                </div>
              </div>
              <div class="col-md-6 pt-md-5 d-flex justify-container-center">
                <div class="pt-sml">
                  <p class="text-white fst-italic px-3">
                    I recently had the pleasure of observing Rebecca work with a
                    client. She had just the right balance of structure and
                    flexibility that made it easy for her to cope with telecoms
                    challenges in the session, without it interfering in the
                    delivery of her material. She was warm and personable and
                    clearly informed, so a balance between competence and
                    empathy! I won’t hesitate to use her service in the future
                    and highly recommend her as a facilitator.
                  </p>
                  <h4 class="fw-bold text-white px-3">
                    - Wayne Kruger | Synchronicity.
                  </h4>
                </div>
              </div>
              <h5 class="pt-5 text-light-blue"></h5>
              <!-- <img
                src="../../images/Shritama-Saha.png"
                class="img-fluid mb-4 img-thumb-size2 btn-why-intern slider-image-intern"
                alt=""
              /> -->
            </slide>
            <slide
              class="d-flex align-items-center justify-content-around flex-md-row flex-column"
            >
              <div class="col-md-2 offset-md-2">
                <div class="pt-sml d-flex justify-content-center">
                  <img
                    src="../assets/images/Gail-Baerecke.webp"
                    class="img-fluid reviews"
                    alt=""
                  />
                </div>
              </div>
              <div class="col-md-6 pt-md-5 d-flex justify-container-center">
                <div class="pt-sml">
                  <p class="text-white fst-italic px-3">
                    When I met and got to work with Rebecca from AGILE, she
                    introduced me to a whole new meaning for the Post-It. We
                    found her at a time when change was taking place in our
                    business and planning and a clear head was needed to plot
                    and plan the future. Her practical way of looking at things
                    helped us to categorise what was needed to be done, allocate
                    who needed to do what and mobilise the team to implement it.
                    She was literally a breath of fresh air.
                  </p>
                  <h4 class="fw-bold text-white px-3">
                    - Gail Baerecke | Chris Burger Petro Jackson Players' Fund.
                  </h4>
                </div>
              </div>
              <h5 class="pt-5 text-light-blue"></h5>
              <!-- <img
                src="../../images/Shritama-Saha.png"
                class="img-fluid mb-4 img-thumb-size2 btn-why-intern slider-image-intern"
                alt=""
              /> -->
            </slide>
            <slide
              class="d-flex align-items-center justify-content-around flex-md-row flex-column"
            >
              <div class="col-md-2 offset-md-2">
                <div class="pt-sml d-flex justify-content-center">
                  <img
                    src="../assets/images/Kimberley-Taylor.webp"
                    class="img-fluid reviews"
                    alt=""
                  />
                </div>
              </div>
              <div class="col-md-6 pt-md-5 d-flex justify-container-center">
                <div class="pt-sml">
                  <p class="text-white fst-italic px-3">
                    Rebecca has been working with our company since September
                    2019. It has been an absolute pleasure, and I have
                    personally witnessed how the team has grown, including
                    myself. Our team is a remote team, but Rebecca was able to
                    help us overcome the challenges of not always being in the
                    same vicinity. Communication channels were smoother, and she
                    helped create opportunities for connection. The
                    retrospectives held have been very valuable in improving
                    honest and open conversations between all of us; revealing
                    issues before they become bigger and unsolvable; and we have
                    been able to collectively adapt our processes as a team. I
                    have recommended her to numerous people and will continue to
                    do so.
                  </p>
                  <h4 class="fw-bold text-white px-3">
                    - Kimberley Taylor | Founder and CEO of Loop
                  </h4>
                </div>
              </div>
              <h5 class="pt-5 text-light-blue"></h5>
              <!-- <img
                src="../../images/Shritama-Saha.png"
                class="img-fluid mb-4 img-thumb-size2 btn-why-intern slider-image-intern"
                alt=""
              /> -->
            </slide>
          </carousel>
        </div>
      </div>
    </section>
    <section class="bg-white">
      <div class="container-fluid pt-sml pb-sml">
        <div class="row">
          <div
            class="col-md-2 d-flex align-items-center justify-content-center"
          >
            <h2 class="text-orange text-indie mt-2">Case studies:</h2>
          </div>
          <div
            class="col-md-2 d-flex align-items-center justify-content-center"
          >
            <img
              src="../assets/images/law.svg"
              class="img-fluid py-5 case-study"
              alt=""
            />
          </div>
          <div
            class="col-md-2 d-flex align-items-center justify-content-center"
          >
            <img
              src="../assets/images/loop.png"
              class="img-fluid py-5 case-study"
              alt=""
            />
          </div>
          <div
            class="col-md-2 d-flex align-items-center justify-content-center"
          >
            <img
              src="../assets/images/ajmtax.png"
              class="img-fluid py-5 case-study"
              alt=""
            />
          </div>
          <div
            class="col-md-2 d-flex align-items-center justify-content-center"
          >
            <img
              src="../assets/images/Much-Asphalt.svg"
              class="img-fluid py-5 case-study"
              alt=""
            />
          </div>
          <div
            class="col-md-2 d-flex align-items-center justify-content-center"
          >
            <img
              src="../assets/images/enamel-logo-color.png"
              class="img-fluid py-5 case-study"
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
    <section class="bg-dark-grey">
      <div class="container pb-lg">
        <div class="row">
          <div class="col-lg-5 text-center pt-lg">
            <div class="d-flex align-items-center">
              <img
                src="../assets/images/coffee-cup.svg"
                class="img-fluid m-auto"
                alt=""
              />
              <h2 class="text-white text-indie mt-2 d-lg-block d-none">
                Get in touch
              </h2>
            </div>
            <h2 class="text-white text-indie mt-2 d-lg-none d-block">
              Get in touch
            </h2>

            <p class="text-white pt-sml fst-italic text-start">
              Offices are not my thing … a “hang out” that feeds my soul IS my
              thing. Like minded people, chai latte, creative artwork, bright
              colours, a touch of Africa but not bound to borders and “cross the
              threshold” thinking and meaningful conversations do it for me.
            </p>
            <div class="row text-white fst-italic text-start p pt-5">
              <div class="col-md-4">Phone:</div>
              <div class="col-md-8">
                <a
                  href="tel:+27765753518"
                  class="footer-links text-decoration-underline"
                  >+27 (0) 76 575 3518</a
                >
              </div>
            </div>
            <div class="row text-white fst-italic text-start p">
              <div class="col-md-4">Whatsapp:</div>
              <div class="col-md-8">
                <a
                  href=" https://wa.me/27765753518"
                  target="_blank"
                  class="footer-links text-decoration-underline"
                  >+27 (0) 76 575 3518</a
                >
              </div>
            </div>
            <div class="row text-white fst-italic text-start p">
              <div class="col-md-4">Email:</div>
              <div class="col-md-8">
                <a
                  href="mailto:rebecca@agilesticks.com"
                  class="footer-links text-decoration-underline"
                  >rebecca@agilesticks.com</a
                >
              </div>
            </div>
            <div class="pt-sml footer-icons d-flex">
              <a
                href="https://www.facebook.com/rebecca.t.cameron.3"
                target="_blank"
                class="px-4 footer-icons"
                ><img
                  src="../assets/images/facebook.svg"
                  class="img-fluid hero-icons"
                  alt=""
              /></a>
              <a
                href="https://www.instagram.com/rebecca.t.cameron.3/"
                target="_blank"
                class="px-4 footer-icons"
                ><img
                  src="../assets/images/instagram.svg"
                  class="img-fluid hero-icons"
                  alt=""
              /></a>
              <a
                href="https://twitter.com/BecksCameronZA"
                target="_blank"
                class="px-4 footer-icons"
                ><img
                  src="../assets/images/twitter.svg"
                  class="img-fluid hero-icons"
                  alt=""
              /></a>
              <a
                href="https://www.linkedin.com/in/rebecca-tara-cameron-b7278114b/"
                target="_blank"
                class="px-4 footer-icons"
                ><img
                  src="../assets/images/linkedin.svg"
                  class="img-fluid hero-icons"
                  alt=""
              /></a>
              <a
                href="https://wa.me/27765753518"
                target="_blank"
                class="px-4 footer-icons"
                ><img
                  src="../assets/images/whatsapp.svg"
                  class="img-fluid hero-icons"
                  alt=""
              /></a>
            </div>
          </div>
          <div class="col-lg-6 offset-lg-1 pt-lg text-center" id="contact">
            <div class="d-flex align-items-center pt-5">
              <img
                src="../assets/images/open-email.svg"
                class="img-fluid m-auto"
                alt=""
              />
              <h2 class="text-white text-indie mt-2 d-lg-block d-none">
                Contact Me
              </h2>
            </div>
            <h2 class="text-white text-indie mt-2 d-lg-none d-block">
              Contact Me
            </h2>
            <div>
              <div class="mb-3 pt-sml">
                <div class="row">
                  <div class="col-md-2">
                    <label for="exampleFormControlInput1" class="form-label"
                      >Name</label
                    >
                  </div>
                  <div class="col-md-10">
                    <input
                      type="email"
                      class="form-control"
                      id="exampleFormControlInput1"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <div class="row">
                  <div class="col-md-2">
                    <label for="exampleFormControlInput1" class="form-label"
                      >Phone</label
                    >
                  </div>
                  <div class="col-md-10">
                    <input
                      type="email"
                      class="form-control"
                      id="exampleFormControlInput1"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <div class="row">
                  <div class="col-md-2">
                    <label for="exampleFormControlInput1" class="form-label"
                      >Email</label
                    >
                  </div>
                  <div class="col-md-10">
                    <input
                      type="email"
                      class="form-control"
                      id="exampleFormControlInput1"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <div class="row">
                  <div class="col-md-2">
                    <label for="exampleFormControlInput1" class="form-label"
                      >Message</label
                    >
                  </div>
                  <div class="col-md-10">
                    <textarea
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      rows="3"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div>
                <div class="d-flex justify-content-end">
                  <button class="btn btn-orange-foot px-5 mt-5 pt-3 h3">
                    Send
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
export default {
  components: {
    Carousel,
    Slide,
  },
};
</script>

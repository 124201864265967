import Vue from "vue";
import App from "./App.vue";
import router from "./router";

Vue.config.productionTip = false;

Vue.component("HeaderMain", require("./components/HeaderMain.vue").default);
Vue.component("FooterMain", require("./components/FooterMain.vue").default);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
